import { getCurrentInstance } from '@vue/composition-api';

import { COLOR_SHADES } from '@/components/builder-drawers/drawers/partials/constants/styles';
import { COLORS } from '@/constants/globalStyles';
import { colorShade } from '@/utils/colorShade';
import {
	isCustomColor,
	stripColorFromVariable,
} from '@/utils/colorVariableModifiers';

export const useColors = () => {
	const { $store } = getCurrentInstance() ?? {};
	const { colors } = getCurrentInstance()?.$store?.state?.website?.styles ?? {};

	const setColorWithShades = (color, colorCategory) => {
		const customColor = !isCustomColor(color) ? colors[stripColorFromVariable(color)] : color;

		$store.commit('setStyleProperty', {
			element: COLORS,
			property: colorCategory,
			value: customColor,
		});

		COLOR_SHADES.forEach((colorType) => $store.commit('setStyleProperty', {
			element: COLORS,
			property: `${colorCategory}-${colorType.type}`,
			value: colorShade(customColor, colorType.shade),
		}));
	};

	return { setColorWithShades };
};
