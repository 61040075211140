export const COLOR_SHADES = [
	{
		type: 'light',
		shade: 20,
	},
	{
		type: 'dark',
		shade: -25,
	},
	{
		type: 'accent',
		shade: 10,
	},
];
