var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.$options.MAIN_COLORS),function(mainColor){return _c('MultipleColorSet',{key:mainColor.color,attrs:{"color":mainColor.color,"label":mainColor.label}})}),_c('div',{staticClass:"colors__card"},[_c('ZyroLabel',[_vm._v(" Dark and light ")]),_c('div',{staticClass:"colors__container"},_vm._l((_vm.$options.DARK_LIGHT),function(color){return _c('Tooltip',{key:color,staticClass:"colors__tooltip",attrs:{"mode":"dark","call-to-action":"hover","content-position":"absolute","size":"small","forced-position":{
					top: '-60px',
					left: '-15px'
				},"forced-triangle-position":{
					top: 'unset',
					bottom: '-4px'
				},"cursor-type":"help"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('ZyroColorPickerPlain',{staticClass:"dark-picker",attrs:{"value":_vm.website.styles.colors[color],"is-popup":true,"style-drawer":true,"picker-size":"56px","border-radius":"5px"},on:{"close":function($event){return _vm.setStyleProperty({
							element: 'colors',
							property: ("" + color),
							value: $event
						})}}})]},proxy:true}],null,true)},[_c('div',[_vm._v(" "+_vm._s((color + ": " + (_vm.website.styles.colors[color])))+" ")])])}),1)],1),_c('ZyroLabel',{staticClass:"accent-label"},[_vm._v(" Accent colors ")]),_vm._l((_vm.$options.ACCENT_COLORS),function(accentColor){return _c('MultipleColorSet',{key:accentColor.color,attrs:{"color":accentColor.color,"label":accentColor.label,"label-bold":false,"border-bottom":false}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }