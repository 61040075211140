var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"colors__card",class:_vm.borderBottom ? ''
		: 'colors__card--noborder'},[_c('ZyroLabel',{attrs:{"bold":_vm.labelBold}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"colors__container"},[_c('Tooltip',{staticClass:"colors__tooltip",attrs:{"mode":"dark","call-to-action":"hover","content-position":"absolute","size":"small","forced-position":{
				top: '-60px',
				left: '-15px'
			},"forced-triangle-position":{
				top: 'unset',
				bottom: '-4px'
			},"cursor-type":"help"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('ZyroColorPickerPlain',{attrs:{"value":_vm.website.styles.colors[_vm.color],"is-popup":true,"style-drawer":true,"picker-size":"56px","border-radius":"5px"},on:{"close":function($event){return _vm.setColorWithShades($event, _vm.color)}}})]},proxy:true}])},[_c('div',[_vm._v(" "+_vm._s((_vm.color + ": " + (_vm.website.styles.colors[_vm.color])))+" ")])]),_c('span',{staticClass:"colors__separator"}),_c('div',{staticClass:"colors__type-container"},_vm._l((_vm.$options.COLOR_SHADES),function(colorType){return _c('Tooltip',{key:(_vm.color + "-" + (colorType.type)),staticClass:"colors__tooltip",attrs:{"mode":"dark","call-to-action":"hover","content-position":"absolute","size":"small","forced-position":{
					top: '-80px',
					left: '-15px'
				},"forced-triangle-position":{
					top: 'unset',
					bottom: '-4px'
				},"cursor-type":"help"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"colors__item",style:(("background-color: " + (_vm.website.styles.colors[(_vm.color + "-" + (colorType.type))]) + ";"))})]},proxy:true}],null,true)},[_c('div',[_vm._v(" "+_vm._s((_vm.color + "-" + (colorType.type) + ": " + (_vm.website.styles.colors[(_vm.color + "-" + (colorType.type))])))+" ")])])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }