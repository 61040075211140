<template>
	<div
		class="colors__card"
		:class="borderBottom ? ''
			: 'colors__card--noborder'"
	>
		<ZyroLabel :bold="labelBold">
			{{ label }}
		</ZyroLabel>
		<div class="colors__container">
			<Tooltip
				class="colors__tooltip"
				mode="dark"
				call-to-action="hover"
				content-position="absolute"
				size="small"
				:forced-position="{
					top: '-60px',
					left: '-15px'
				}"
				:forced-triangle-position="{
					top: 'unset',
					bottom: '-4px'
				}"
				cursor-type="help"
			>
				<template #trigger>
					<ZyroColorPickerPlain
						:value="website.styles.colors[color]"
						:is-popup="true"
						:style-drawer="true"
						picker-size="56px"
						border-radius="5px"
						@close="setColorWithShades($event, color)"
					/>
				</template>
				<div>
					{{ `${color}: ${website.styles.colors[color]}` }}
				</div>
			</Tooltip>

			<span class="colors__separator" />

			<div class="colors__type-container">
				<Tooltip
					v-for="colorType in $options.COLOR_SHADES"
					:key="`${color}-${colorType.type}`"
					class="colors__tooltip"
					mode="dark"
					call-to-action="hover"
					content-position="absolute"
					size="small"
					:forced-position="{
						top: '-80px',
						left: '-15px'
					}"
					:forced-triangle-position="{
						top: 'unset',
						bottom: '-4px'
					}"
					cursor-type="help"
				>
					<template #trigger>
						<div
							class="colors__item"
							:style="`background-color: ${website.styles.colors[`${color}-${colorType.type}`]};`"
						/>
					</template>
					<div>
						{{
							`${color}-${colorType.type}: ${website.styles.colors[`${color}-${colorType.type}`]}`
						}}
					</div>
				</Tooltip>
			</div>
		</div>
	</div>
</template>
<script>
import { Tooltip } from '@zyro-inc/shared-ui/components/popups';
import { mapState } from 'vuex';

import { COLOR_SHADES } from '@/components/builder-drawers/drawers/partials/constants/styles';
import { useColors } from '@/components/builder-drawers/drawers/partials/use/useColors';

export default {
	COLOR_SHADES,
	components: { Tooltip },
	props: {
		color: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		labelBold: {
			type: Boolean,
			default: true,
		},
		borderBottom: {
			type: Boolean,
			default: true,
		},
	},
	setup() {
		const { setColorWithShades } = useColors();

		return { setColorWithShades };
	},
	computed: { ...mapState(['website']) },
};
</script>
<style lang="scss" scoped>
	@import './StyleDrawerColors.scss';
</style>
