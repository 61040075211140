<template>
	<div>
		<MultipleColorSet
			v-for="mainColor in $options.MAIN_COLORS"
			:key="mainColor.color"
			:color="mainColor.color"
			:label="mainColor.label"
		/>
		<div class="colors__card">
			<ZyroLabel>
				Dark and light
			</ZyroLabel>
			<div class="colors__container">
				<Tooltip
					v-for="color in $options.DARK_LIGHT"
					:key="color"
					class="colors__tooltip"
					mode="dark"
					call-to-action="hover"
					content-position="absolute"
					size="small"
					:forced-position="{
						top: '-60px',
						left: '-15px'
					}"
					:forced-triangle-position="{
						top: 'unset',
						bottom: '-4px'
					}"
					cursor-type="help"
				>
					<template #trigger>
						<ZyroColorPickerPlain
							class="dark-picker"
							:value="website.styles.colors[color]"
							:is-popup="true"
							:style-drawer="true"
							picker-size="56px"
							border-radius="5px"
							@close="setStyleProperty({
								element: 'colors',
								property: `${color}`,
								value: $event
							})"
						/>
					</template>
					<div>
						{{ `${color}: ${website.styles.colors[color]}` }}
					</div>
				</Tooltip>
			</div>
		</div>

		<ZyroLabel class="accent-label">
			Accent colors
		</ZyroLabel>
		<MultipleColorSet
			v-for="accentColor in $options.ACCENT_COLORS"
			:key="accentColor.color"
			:color="accentColor.color"
			:label="accentColor.label"
			:label-bold="false"
			:border-bottom="false"
		/>
	</div>
</template>
<script>
import { Tooltip } from '@zyro-inc/shared-ui/components/popups';
import {
	mapState,
	mapMutations,
} from 'vuex';

import MultipleColorSet from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/colors/MultipleColorSet.vue';

const MAIN_COLORS = [
	{
		color: 'primary',
		label: 'Primary',
	},
	{
		color: 'secondary',
		label: 'Secondary',
	},
];
const DARK_LIGHT = [
	'dark',
	'light',
];
const ACCENT_COLORS = [
	{
		color: 'accent-1',
		label: 'Accent One',
	},
	{
		color: 'accent-2',
		label: 'Accent Two',
	},
];

export default {
	MAIN_COLORS,
	DARK_LIGHT,
	ACCENT_COLORS,
	components: {
		MultipleColorSet,
		Tooltip,
	},
	computed: { ...mapState(['website']) },
	methods: { ...mapMutations(['setStyleProperty']) },
};
</script>
<style lang="scss" scoped>
@import './StyleDrawerColors.scss';

.dark-picker {
	margin-right: 8px;
}

.accent-label {
	margin-bottom: 32px;
}
</style>
